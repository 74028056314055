const permissionColumns = {
    1: [],
    2: ['retention', 'attach', 'conciliation', 'accounting', 'competence', 'actions', 'paid'],
    3: ['payment', 'payment_approach', 'buyer', 'conciliation', 'accounting', 'competence', 'paid'],
    4: ['constrution', 'dueDate', 'payment_approach', 'attach', 'buyer', 'conciliation', 'appropriation', 'actions', 'paid']
};

const allowedPrintColumns = ['companyName', 'constrution', 'supplier', 'invoiceID', 'payment', 'amount', 'comments'];

function checkIfHasPermission(accessor, allowedColumns) {
  return !allowedColumns.includes(accessor);
}

function isAllowedForPrint(accessor) {
  return allowedPrintColumns.includes(accessor);
}

function generateTableData(permission) {
  const allowedColumns = permissionColumns[permission] || [];

  return [{
    Header: 'Conciliação Bancária',
    columns: [
      { 
        Header: 'Empresa', 
        accessor: 'companyName', 
        printMode: isAllowedForPrint('companyName') ? `print print-${permission}` : 'no-print',
        width: 80, 
        textAlign: 'center' 
      },
      { 
        Header: 'Obra/CC', 
        accessor: 'constrution', 
        printMode: isAllowedForPrint('constrution') ? `print print-${permission}` : 'no-print',
        width: 80, 
        textAlign: 'center' 
      },
      { 
        Header: 'Fornecedor', 
        accessor: 'supplier', 
        printMode: isAllowedForPrint('supplier') ? `print print-${permission}` : 'no-print',
        width: 150, 
        textAlign: 'center' 
      },
      { 
        Header: 'Nº Nota Fiscal', 
        accessor: 'invoiceID', 
        printMode: isAllowedForPrint('invoiceID') ? `print print-${permission}` : 'no-print',
        width: 70, 
        textAlign: 'center', 
        className: 'isChecked' 
      },
      { 
        Header: 'Retenção', 
        accessor: 'retention', 
        printMode: isAllowedForPrint('retention') ? `print print-${permission}` : 'no-print',
        width: 60, 
        textAlign: 'center' 
      },
      { 
        Header: 'Vencimento', 
        accessor: 'dueDate', 
        printMode: isAllowedForPrint('dueDate') ? `print print-${permission}` : 'no-print',
        width: 100, 
        textAlign: 'center' 
      },
      { 
        Header: 'Data Pagamento', 
        accessor: 'payment', 
        printMode: isAllowedForPrint('payment') ? `print print-${permission}` : 'no-print',
        width: 100, 
        textAlign: 'center' 
      },
      { 
        Header: 'Forma Pgto', 
        accessor: 'payment_approach', 
        printMode: isAllowedForPrint('payment_approach') ? `print print-${permission}` : 'no-print',
        width: 100, 
        textAlign: 'center' 
      },
      { 
        Header: 'Valor pago', 
        accessor: 'amount', 
        printMode: isAllowedForPrint('amount') ? `print print-${permission}` : 'no-print',
        width: 80, 
        textAlign: 'center' 
      },
      { 
        Header: 'NF & Boleto', 
        accessor: 'invoice_bill', 
        printMode: isAllowedForPrint('invoice_bill') ? `print print-${permission}` : 'no-print',
        width: 40, 
        textAlign: 'center', 
        className: `no-print`
      },
      { 
        Header: 'Vincular', 
        accessor: 'attach', 
        printMode: isAllowedForPrint('attach') ? `print print-${permission}` : 'no-print',
        width: 60, 
        textAlign: 'center' 
      },
      { 
        Header: 'Comprador', 
        accessor: 'buyer', 
        printMode: isAllowedForPrint('buyer') ? `print print-${permission}` : 'no-print',
        width: 60, 
        textAlign: 'center' 
      },
      { 
        Header: 'Visto Comprador', 
        accessor: 'paid', 
        printMode: isAllowedForPrint('paid') ? `print print-${permission}` : 'no-print',
        width: 30, 
        textAlign: 'center' 
      },
      { 
        Header: 'Pago', 
        accessor: 'conciliation', 
        printMode: isAllowedForPrint('conciliation') ? `print print-${permission}` : 'no-print',
        width: 50, 
        textAlign: 'center' 
      },
      { 
        Header: 'Apropriação', 
        accessor: 'appropriation', 
        printMode: isAllowedForPrint('appropriation') ? `print print-${permission}` : 'no-print',
        width: 90, 
        textAlign: 'center' 
      },
      { 
        Header: 'C.C. Contábil', 
        accessor: 'accounting', 
        printMode: isAllowedForPrint('accounting') ? `print print-${permission}` : 'no-print',
        width: 90, 
        textAlign: 'center' 
      },
      { 
        Header: 'Competência Contábil', 
        accessor: 'competence', 
        printMode: isAllowedForPrint('competence') ? `print print-${permission}` : 'no-print',
        width: 65, 
        textAlign: 'center' 
      },
      { 
        Header: 'Observação', 
        accessor: 'comments', 
        printMode: isAllowedForPrint('comments') ? `print print-${permission}` : 'no-print',
        width: 80, 
        textAlign: 'center' 
      },
      { 
        Header: 'Ações', 
        accessor: 'actions', 
        printMode: isAllowedForPrint('actions') ? `print print-${permission}` : 'no-print',
        width: 50, 
        textAlign: 'center', 
        className: `no-print`
      }
    ].filter(column => checkIfHasPermission(column.accessor, allowedColumns))
  }];
}

const permission = parseInt(sessionStorage.getItem('MonteVerdePermission'), 10);
const tableData = generateTableData(permission);

export default tableData;
