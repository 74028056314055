import React, { useState } from 'react';
import constructionsService from '../../services/constructions';
import loaderIcon from '../../assets/loader.svg';
import PrimaryButton from '../../components/Layout/PrimaryButton';
import editIcon from '../../assets/edit-icon.png';

import ModalConstructions from '../../components/ModalConstructions';

import './Constructions.scss';

const Constructions = () => {
  const initialFields = {
    name: { value: '', valid: true, validationMessage: '' },
    isActive: { value: true, valid: true, validationMessage: '' },
    comments: { value: '', valid: true, validationMessage: '' },
  };

  const [fields, setFields] = useState({ ...initialFields });
  const [loader, setLoader] = useState(false);
  const [backendError, setBackendError] = useState('');
  const [successForm, setSuccessForm] = useState(false);
  const [constructionsList, setConstructionsList] = useState(false);
  const [editConstruction, setEditConstruction] = useState(false);

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: {
        ...prevFields[name],
        value: value,
      },
    }));
  };

  const generateRandomCNPJ = () => {
    const randomNumbers = () => Math.floor(Math.random() * 99999999).toString().padStart(8, '0');
    const randomPart = randomNumbers() + randomNumbers();
    return `${randomPart.substring(0, 2)}.${randomPart.substring(2, 5)}.${randomPart.substring(5, 8)}/${randomPart.substring(8, 12)}-${randomPart.substring(12, 14)}`;
  };

  
  const saveConstruction = async (constructionData) => {
    try {
      if (!editConstruction) {
         /*const formData = {
          name: fields.name.value,
          email: `monteverde-${fields.name.value.replace(/\s+/g, '').toLowerCase()}@metodoc.com.br`, // Replace spaces and convert to lowercase
          cnpj: generateRandomCNPJ(),
          companyId: 57, // Don Felipe ID Metodoc
          users: [330] // User Don Felipe Metodoc
        };*/

        //const responseMTDB = await constructionsService.createConstructionMtDB(formData);
        //constructionData.mtId = responseMTDB.data.id;
        await constructionsService.createConstruction(constructionData);
      } else {
        await constructionsService.updateConstruction(constructionData.constructionID, constructionData);
      }
      setSuccessForm(true);
      setBackendError('');
    } catch (error) {
      console.error('Error fetching expenses:', error);
      setBackendError('Algo deu errado! Tente novamente.');
    } finally {
      setLoader(false);
    }
  };

  const handleCloseModal = (e) => {
    setConstructionsList(false);
  }

  const handleConstructionChange = async (data) => {
    setFields((prevFields) => ({
      comments: {
        ...prevFields['comments'],
        value: data.comments,
      },
      name: {
        ...prevFields['name'],
        value: data.name,
      },
      constructionID: {
        ...prevFields['constructionID'],
        value: data.constructionID,
      },
      isActive: {
        ...prevFields['isActive'],
        value: data.isActive,
      }
    }));
    setConstructionsList(false);
    setEditConstruction(true);
  }

  const validateConstructionName = (name) => {
    return name.trim() !== '';
  };

  const validateForm = () => {
    // Validation for Razão Social and CNPJ
    const isNameValid = validateConstructionName(fields.name.value);

    setFields((prevFields) => ({
      ...prevFields,
      ['name']: {
        ...prevFields['name'],
        valid: fields.name.value.length > 2,
      },
    }));

    if (!isNameValid) {
      setLoader(false);
      return false;
    } else {
      setBackendError('');
    }

    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoader(true);
    const formData = {};
    Object.entries(fields).forEach(([fieldName, fieldData]) => {
      formData[fieldName] = fieldData.value;
    });

    try {
      await saveConstruction(formData);
      setSuccessForm(true);
    } catch (error) {
      console.error('Error saving companie:', error);
      setBackendError('Algo deu errado! Tente novamente.');
    } finally {
      setLoader(false);
    }
  };

  const resetForm = () => {
    setFields({ ...initialFields });
    setLoader(false);
    setBackendError('');
    setSuccessForm(false);
    setEditConstruction(false);
  };

  return (
    <>
      {constructionsList && (
        <ModalConstructions onCloseModal={handleCloseModal} constructionData={handleConstructionChange} />
      )}

      <div className='constructions-screen wrapper-content form-box'>
        {!backendError && !successForm && (
          <>
            {editConstruction && (
              <button className="back-button" onClick={(e) => { e.preventDefault(); setLoader(true); window.location.reload(); }} style={{ background: 'none', border: 'none', cursor: 'pointer', position: 'absolute', left: 10, top: 15 }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            )}
            <h2>{!editConstruction ? 'Cadastro de Obra/CC' : 'Editar Obra/CC'}</h2>
            <button className='editSup' onClick={() => setConstructionsList(true)}><img src={editIcon} alt="Edit icon" style={{ width: '18px', height: '18px' }} /></button>
          </>
        )}

        {backendError && (
          <>
            <h2 className="error-message">
              {backendError}
            </h2>
            <PrimaryButton fullWidth onClick={resetForm}>
              Voltar
            </PrimaryButton>
          </>
        )}

        {!backendError && successForm && (
          <div style={{ width: '60%', margin: '0 auto' }}>
            <h2>{!editConstruction ? 'Cadastro realizado no sistema!' : 'Edição realizada com sucesso!'}</h2>
            <PrimaryButton fullWidth onClick={resetForm}>
              Voltar
            </PrimaryButton>
          </div>
        )}

        {loader && !backendError && !successForm ? (
          <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
        ) : (
          !successForm && (
            <form onSubmit={handleSubmit} className="constructions-screen__form" action="#">
              <label>
                Nome<sup>*</sup>
                <input
                  type="text"
                  name='name'
                  className={fields.name.valid === false ? 'has-error' : ''}
                  onChange={handleInputChange}
                  defaultValue={fields.name.value}
                  tabIndex={1}
                  onBlur={(e) => {
                    validateForm();
                  }}
                />
              </label>
              
              <label>
                Ativo<sup></sup>
                <input
                  align="left"
                  type="checkbox"
                  name="isActive"
                  checked={fields.isActive.value}
                  onChange={handleInputChange}
                  tabIndex={2}
                />
              </label>
              <label>
                Observação
                <textarea
                  name='comments'
                  onChange={handleInputChange}
                  defaultValue={fields.comments.value}
                  tabIndex={6}
                />
              </label>
              <input className={'btn--primary'} type='submit' value={!editConstruction ? "Salvar" : "Salvar Edição"} tabIndex={7} />
            </form>
          )
        )}
      </div>
    </>
  );
};

export default Constructions;
