import axios from 'axios'

import config from '../config'

const getAll = () => {
  return axios.get(`${config.baseUrl}/companies`, {
    headers: {
      'Authorization': `Bearer ${ sessionStorage.getItem('MonteVerdeToken') }`,
    },
  })
}

const getById = id => {
  return axios.get(`${config.baseUrl}/companies/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('MonteVerdeToken')}`,
    },
  })
}

const createCompany = company => {
  return axios.post(`${config.baseUrl}/companies`, company, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('MonteVerdeToken')}`,
    },
  })
}

const updateCompany = (companyId, updates) => {
  return axios.put(`${config.baseUrl}/companies/${companyId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('MonteVerdeToken')}`,
    },
  })
}

export default { getAll, getById, createCompany, updateCompany }
