import React, { useState, Component } from 'react';
import { Redirect } from 'react-router-dom'


import { openLoaderModal, closeLoaderModal } from '../../actions/ui';

import loginService from '../../services/login'
import authService from '../../services/auth'
import validator from './validator'

import loaderIcon from '../../assets/loader.svg';

import PrimaryButton from '../../components/Layout/PrimaryButton';

import './ChangePassword.scss'


class ChangePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      passwordChangedSuccessfully: false,
      passwordError: false,
      unmatchedPasswords: false,
      serverError: false,
    }

    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this)
    this.handleNewPasswordConfirmationChange = this.handleNewPasswordConfirmationChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value })
  }

  handleNewPasswordChange(e) {
    this.setState({ newPassword: e.target.value })
  }

  handleNewPasswordConfirmationChange(e) {
    this.setState({ newPasswordConfirmation: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()

    const params = {
      password: this.state.password,
      newPassword: this.state.newPassword,
      random: sessionStorage.getItem('MonteVerdeUserId'),
    }

    const { newPassword, newPasswordConfirmation } = this.state

    if (!validator.validatePasswords(newPassword, newPasswordConfirmation)) {
      this.setState({ passwordError: true, unmatchedPasswords: false, serverError: false })
    }

    if (validator.validatePasswords(newPassword, newPasswordConfirmation) && !validator.passwordsMatch(newPassword, newPasswordConfirmation)) {
      this.setState({ unmatchedPasswords: true, passwordError: false, serverError: false })
    }
    //this.props.openLoaderModal()
    if (validator.validatePasswords(newPassword, newPasswordConfirmation) && validator.passwordsMatch(newPassword, newPasswordConfirmation)) {
      loginService.changePassword(params).then(response => {
        sessionStorage.setItem('MonteVerdeToken', response.data.token)
        this.setState({ passwordChangedSuccessfully: true })
      }).catch(err => {
        this.setState({ serverError: true })
      }).then(() => {
        
      })
    }
  }

  render() {
    const passwordChangedSuccessfully = () => {
      if (this.state.passwordChangedSuccessfully) {
        this.setState({ 
          password: '',
          newPassword: '',
          newPasswordConfirmation: '',
          passwordChangedSuccessfully: true,
          passwordError: false,
          unmatchedPasswords: false,
          serverError: false,
        })
        return <p className="change-password-screen__passSuccess">Senha alterada com sucesso!</p>
      } else {
        return null
      }
    }
    
    const passwordError = () => {
      if (this.state.passwordError) {
        return <p className="change-password-screen__has-error">A senha precisa conter pelo menos 8 caracteres.</p>
      } else {
        return null
      }
    }

    const unmatchedPasswords = () => {
      if (this.state.unmatchedPasswords) {
        return <p className="change-password-screen__has-error">As senhas são diferentes.</p>
      } else {
        return null
      }
    }

    const serverError = () => {
      if (this.state.serverError) {
        return <p className="change-password-screen__has-error">Opss! Possíveis problemas: Senha atual incorreta ou erro inesperado! Tente novamente.</p>
      } else {
        return null
      }
    }

    return (
      <div className='change-password-screen wrapper-content form-box'>
        <h2>{'Alterar Senha'}</h2>
          {passwordError()}
          {unmatchedPasswords()}
          {serverError()}
          {passwordChangedSuccessfully()}

          <form className="change-password-screen__form" action="#">
            <div className='column'>
              <label>
                Senha atual<sup>*</sup>
                <input
                  className="change-password-screen__input"
                  type="password"
                  placeholder="Senha atual"
                  onChange={this.handlePasswordChange}
                  value={this.state.password}
                />
              </label>
              <label>
                Nova senha<sup>*</sup>
                <input
                  className="change-password-screen__input"
                  type="password"
                  placeholder="Nova senha"
                  onChange={this.handleNewPasswordChange}
                  value={this.state.newPassword}
                />
              </label>
              <label>
                Confirmação da nova senha<sup>*</sup>
                <input
                  className="change-password-screen__input"
                  type="password"
                  placeholder="Confirmação da nova senha"
                  onChange={this.handleNewPasswordConfirmationChange}
                  value={this.state.newPasswordConfirmation}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.handleSubmit(e)
                    }
                  }}
                />
              </label>
              <PrimaryButton fullWidth onClick={this.handleSubmit}>Trocar senha</PrimaryButton>
            </div>
          </form>
      </div>
    )
  }
}


export default ChangePassword