const isLoggedIn = () => {
  const token = sessionStorage.getItem('MonteVerdeToken')
  return (token !== null && token !== '')
}

const isFirstAccess = () => {
  const firstAccess = sessionStorage.getItem('MonteVerdeFirstAccess')
  return firstAccess === 'true'
}

const isMonteVerdeUser = () => {
  const userPermission = sessionStorage.getItem('MonteVerdePermission')
  return userPermission === 'MonteVerde'
}

const isAdminUser = () => {
  const userPermission = sessionStorage.getItem('MonteVerdePermission')
  return userPermission === 'admin'
}

const isRegularUser = () => {
  const userPermission = sessionStorage.getItem('MonteVerdePermission')
  return userPermission === 'user'
}

const getName = () => {
  const name = sessionStorage.getItem('MonteVerdeUsername')
  return name === null ? '' : name
}

const getUserId = () => {
  const id = sessionStorage.getItem('MonteVerdeUserId')
  return id === null ? '' : id
}

const getCompanyId = () => {
  const id = sessionStorage.getItem('MonteVerdeCompanyId')
  return id === null ? '' : id
}

export default {
  isLoggedIn,
  isFirstAccess,
  isRegularUser,
  isAdminUser,
  isMonteVerdeUser,
  getName,
  getCompanyId,
  getUserId
}
