import axios from 'axios'

import config from '../config'

const login = params => {
  return axios.post(`${config.baseUrl}/login`, params)
}

const changePassword = params => {
  return axios.post(`${config.baseUrl}/changePassword`, params, {
    headers: {
      'Authorization': `Bearer ${ sessionStorage.getItem('MonteVerdeToken') }`,
    },
  })
}

const forgotPassword = params => {
  return axios.post(`${config.baseUrl}/forgotPassword`, params)
}

const logout = () => {
  return new Promise((resolve, reject) => {
    sessionStorage.clear();
    sessionStorage.removeItem('hasReloaded');
    sessionStorage.removeItem('MonteVerdePermission');
    resolve()
  })
}

export default {
  login,
  changePassword,
  forgotPassword,
  logout,
}
