import axios from 'axios'
import config from '../config'

const getAll = () => {
  return axios.get(`${config.baseUrl}/suppliers`, {
    headers: {
      'Authorization': `Bearer ${ sessionStorage.getItem('MonteVerdeToken') }`,
    },
  })
}

const getById = id => {
  return axios.get(`${config.baseUrl}/suppliers/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('MonteVerdeToken')}`,
    },
  })
}

const createSupplier = supplier => {
  return axios.post(`${config.baseUrl}/suppliers`, supplier, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('MonteVerdeToken')}`,
    },
  })
}

const updateSupplier = (supplierId, updates) => {
  return axios.put(`${config.baseUrl}/suppliers/${supplierId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('MonteVerdeToken')}`,
    },
  })
}

export default { getAll, getById, createSupplier, updateSupplier }
